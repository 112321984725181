<template>
  <v-container>
    <v-row class="text-h5">
      <v-col cols="10"> Products </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :items="tableData"
          :itemid="products.map((p) => p.id)"
          :headers="headers"
          :loading="loading"
          :loading-text="loadingText"
          :no-data-text="noDataText"
          sort-by="title"
          :expanded.sync="expanded"
          single-expand
          @click:row="showDetail"
          show-expand
        >
          <template v-slot:item.package="{ item }">
            <v-select
              :items="packages"
              v-model="item.package"
              item-text="name"
              item-value="id"
              @change="updatePackage(item)"
            />
          </template>
          <template v-slot:item.perPackage="{ item }">
            <v-text-field
              v-model="item.perPackage"
              type="Number"
              @input="updateQtyPerPackage(item)"
            />
          </template>
          <template v-slot:item.hoursPerUnit="{ item }">
            <v-text-field
              v-model="item.hoursPerUnit"
              type="Number"
              @input="updateProduct(item.id, { hoursPerUnit: item.hoursPerUnit })"
            />
          </template>
          <template v-slot:expanded-item="{ item }">
            <td :colspan="headers.length+1" class="product-detail-td">
              <product-table-detail :product="item" />
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import strapiApi from "../mixins/strapiApi";
import ProductTableDetail from "../components/ProductTableDetail.vue";
export default {
  name: "ProductTable",
  components: {
    ProductTableDetail,
  },
  mixins: [strapiApi],
  data: () => ({
    loading: true,
    loadingText: "",
    noDataText: "",
    products: [],
    headers: [
      { text: "ShopifyId", value: "id" },
      { text: "Title", value: "title" },
      //{ text: 'Sync', value: 'sync' },
      { text: "PerPkg", value: "perPackage" },
      { text: "Package", value: "package" },
      { text: "Hours Per Unit", value: "hoursPerUnit"}
    ],
    packages: [],
    order: null,
    detailDialog: false,
    expanded: [],
    syncingItem: false,
  }),
  async mounted() {
    try {
      this.loadingText = "Syncing Strapi and Shopify product records";
      await this.syncEndpoint("products");
      this.loadingText = "Getting strapi products";
      this.products = await this.getProducts("");
      this.loadingText = "Getting shipping containers";
      this.packages = await this.getPackages();
      this.loading = false;
    } catch (error) {
      this.noDataText = "Error loading products. Is Strapi service running?";
      this.loading = false;
      console.error(error);
    }
  },
  methods: {
    getstapiId(id) {
      return this.products.find((p) => p.shopifyId == id).id;
    },
    updatePackage(item) {
      this.updateProduct(item.id, { package: item.package });
    },
    updateQtyPerPackage(item) {
      this.updateProduct(item.id, { perPackage: item.perPackage });
    },
    showDetail(e, payload) {
      if (this.expanded[0]) {
        if (this.expanded[0].id == payload.item.id) {
          return (this.expanded = []);
        }
      }
      this.expanded = [payload.item];
    },
  },
  computed: {
    activeProducts() {
      return this.products.filter((p) => {
        return p.shopifyJson.status == "active";
      });
    },
    tableData() {
      return this.activeProducts.map((p) => {
        p.title = p.shopifyJson.title;
        return p;
      });
    },
  },
};
</script>
<style>
.product-detail-td {
  border-color: rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-top-style: none;
  border-width: thin;
}
.v-data-table__expanded__content {
  box-shadow: none !important;
  background-color: #f5f5f5;
}
</style>
